import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import API from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisH,
    faFilter,
    faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { NavItem } from "reactstrap";
import AppConstants from "../../../utils/app-constants";
import SearchBox from "../../../../src/components/search/SearchBox"
import { errorNotification } from "../../../utils/notifications";
import Actions from "./Actions";
import moment from "moment";
import ReturnDetails from "./ReturnDetails";

const ComponentsReturns = () => {

    const [actionShow, setActionShow] = useState();
    const [showFilter, setShowFilter] = useState(false);
    const [UsersData, setUsersData] = useState([]);
    const [open, setOpen] = useState();
    const [openQty, setOpenQty] = useState();

    const fetchReport = async () => {
        const resp = await trackPromise(API.get(`finance/api/v1/returns/componentsreturn`));
        console.log(resp);
        setUsersData(resp);
    };
    useEffect(() => {
        fetchReport();
    }, []);

    console.log(UsersData);

    const filteredData = UsersData.filter((item) => item.status != "Return Note Generated");
    console.log(filteredData);

    const statusFormatter = (cell) => {
        return (
            cell === "In-Progress_In-Progress" ?
                <button className="deactive-btn"><span className="deactive-btn-font">Pending at Finance</span></button> :
                cell === "Completed_Completed" ?
                    <button className="reactive-btn"> <span className="reactive-btn-font">Approved by Finance</span></button> :
                    cell === "On Hold_On Hold" ?
                        <button className="deactive-btn"> <span className="deactive-btn-font">On Hold by Finance</span></button> :
                        cell === "Rejected_Rejected" ?
                            <button className="delete-btn"> <span className="delete-btn-font">Rejected by Finance</span></button> : cell
        );
    };

    function on() {
        document.getElementById("overlay").style.display = "block";
    };

    function off() {
        document.getElementById("overlay").style.display = "none";
        setOpen(false);
    }

    const actionHandler = (rowIndex) => {
        setOpen(true);
        setActionShow(rowIndex);
        on();
    };

    const actionFormatter = (cell, row, rowIndex, formatExtraData) => {
        console.log(row);
        return (
            <div>
                <button className="action-btn" onClick={() => actionHandler(rowIndex)}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                </button>
                {(actionShow === rowIndex) && open ? (
                    <Actions updatedData={fetchReport} rowData={row} />
                ) : null}
            </div>
        );
    };

    const [formValue, setFormValue] = useState({
        category: "",
        subCategory: "",
        skuCode: "",
        componentName: ""
    });

    const handleChange = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };
    const clearFilter = (e) => {
        setFormValue({
            category: "",
            subCategory: "",
            skuCode: "",
            componentName: ""
        });
    };
    const submitHandler = (e) => {
        e.preventDefault();
        console.log(formValue);
        console.log("Filter Applied!");
    };

    function dateFormatter(cell) {
        let date = cell
        if (!cell) {
            return "N/A";
        }
        return `${moment(date).format("DD-MMM-YYYY/HH:mm")}`
    };

    const returnDetailsFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <p
                    style={{
                        fontSize: '14px',
                        color: 'blue',
                        cursor: 'pointer',
                        margin: "0px"
                    }}
                    onClick={() => setOpenQty(rowIndex)}
                >
                    {parseInt(cell)}
                </p>
                {openQty === rowIndex && <ReturnDetails rowData={row} closeModal={setOpenQty} />}
            </div>
        );
    };

    const columns = [
        {
            text: "S.No.",
            // dataField: "id",
            headerAttrs: { width: "50px" },
            headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return rowIndex + 1 + ".";
            },
            sort: true,
        },
        {
            text: "Request ID",
            dataField: "requestID",
            headerAttrs: { width: "200px" },
            headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' }
            // classes: "alignment"
        },
        {
            text: "Request By",
            dataField: "requestedBy",
            headerAttrs: { width: "250px" },
            headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
            classes: "cellsOverflow"
        },
        {
            text: "Request Date",
            dataField: "requestedDate",
            headerAttrs: { width: "150px" },
            headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
            // classes: "alignment",
            formatter: dateFormatter
        },
        {
            text: "Return Details",
            dataField: "returnedQuantity",
            headerAttrs: { width: "120px" },
            headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
            formatter: returnDetailsFormatter,
            formatExtraData: { openQty },
        },
        {
            text: "Status",
            dataField: "status",
            headerAttrs: { width: "220px" },
            headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
            // classes: "alignment"
            formatter: statusFormatter,
        },
        {
            text: "Action",
            // dataField: "title",
            headerAttrs: { width: "120px" },
            headerStyle: { fontWeight: '500', borderTop: '1px solid rgba(0,0,0,0.2)' },
            formatExtraData: { actionShow, open },
            formatter: actionFormatter,
        },
    ];

    let uniqueCategoryArray = [...new Map(UsersData.map((item) => [item["category"], item])).values()];

    return (
        <>
            <div className="bussiness">
                <div id="overlay" onClick={(e) => off(e)}></div>
                <div className="heading">
                    <span>Components Returns</span>
                </div>
                <div
                    type="button"
                    className="btn btn-md"
                    style={{ float: "right", marginTop: "-42px" }}
                >
                    <div
                        style={{ display: "flex", marginRight: "-18px", height: "26px" }}
                    >
                        <div style={{ width: '199px' }}>
                            <SearchBox />
                        </div>
                        <div className="filter-refresh" >
                            <FontAwesomeIcon icon={faRedoAlt} />&nbsp;
                            Refresh
                        </div>
                        <div
                            className="filter-refresh"
                            onClick={() => setShowFilter(!showFilter)}
                        >
                            <FontAwesomeIcon icon={faFilter} />&nbsp;
                            Filters
                        </div>
                    </div>
                </div>
            </div>
            {showFilter && (
                <form onSubmit={submitHandler} className="UHinputfields VMform">
                    <select name="category" value={formValue.category} onChange={handleChange}>
                        <option value='' disabled selected>
                            Category
                        </option>
                        {uniqueCategoryArray.map((item) => {
                            return (<option>{item.category}</option>)
                        })}
                    </select>
                    <input
                        type="text"
                        name="subCategory"
                        value={formValue.subCategory}
                        onChange={handleChange}
                        placeholder="Sub Category"
                        autoComplete="off"
                    />
                    <input
                        type="text"
                        name="skuCode"
                        value={formValue.skuCode}
                        onChange={handleChange}
                        placeholder="SKU Code"
                        autoComplete="off"
                    />
                    <input
                        type="text"
                        name="componentName"
                        value={formValue.componentName}
                        onChange={handleChange}
                        placeholder="Component Name"
                        autoComplete="off"
                    />
                    <button id="apply">
                        Apply
                    </button>
                    <button onClick={clearFilter} id="clear">
                        Clear Filter
                    </button>
                </form>
            )}
            <br></br>

            <BootstrapTable
                wrapperClasses="table-responsive"
                rowClasses="text-nowrap"
                keyField="id"
                data={filteredData}
                columns={columns}
                rowStyle={{ border: '1px solid rgba(0,0,0,0.2)' }}
                pagination={paginationFactory({
                    hidePageListOnlyOnePage: true,
                    showTotal: true,
                    hideSizePerPage: true,
                    paginationSize: 2,
                    sizePerPage: "10",
                })}
                condensed
            />
        </>
    );
};

export default ComponentsReturns;

